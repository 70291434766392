import { tw } from '@/utils/tw';
import BaseImage from 'base/components/standalone/Image/theme';

const Image = tw.theme({
  extend: BaseImage,
  slots: {
    base: 'data-[fill]:aspect-[40/21]',
    captionGroup: 'text-black text-ui-md',
    byline: 'text-gray-600',
  },
});

export default Image;
